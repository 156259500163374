<template>
	<div class="register">
		<el-container>
			<MyHeader></MyHeader>
			<el-main>
				
				<div class="rigster-content">
					<h1 class="regtitle">欢迎注册泰克莱恩医疗病例输入平台</h1>
					<el-form label-width="180px" style="width: 50%;margin: 0px auto;" :model="regForm" ref="regForm" :rules="rules">
						<!-- 1.真实姓名 -->
						<el-form-item label="姓名" prop="dname" >
						    <el-input v-model="regForm.dname" placeholder="请输入真实的医师姓名"></el-input>
						</el-form-item>
						<!--  2.昵称-->
						<el-form-item label="昵称" prop="webName" >
						    <el-input v-model="regForm.webName" placeholder="请输入昵称"></el-input>
						</el-form-item>
						<!-- 3.手机号 -->
						<el-form-item prop="phone" label="手机号码">
							<el-input placeholder="建议使用常用手机" v-model="regForm.phone">
							   <!-- <template slot="prefix"><img src="../assets/register/phone.png" width="20" height="20" style="margin-top: 0.3125rem;"/></template> -->
							  </el-input>
						</el-form-item>
						<!-- 验证码 -->
						<el-form-item label="验证码" prop="userCode">
							<el-input placeholder="请输入短信验证码" v-model="regForm.userCode">
								<!-- <template slot="prefix"><img src="../assets/register/code.png" width="20"
								height="20" style="margin-top: 0.3125rem;" /></template> -->
								<template slot="suffix"><button :disabled="zaicifasong" type="button" id="c"
										@click="getCode">{{phoneCode}}</button></template>
							</el-input>
						</el-form-item>
						<!-- 7.密码 -->
						<!-- <el-form-item label="密码" prop="password">
						    <el-input type="password" v-model="regForm.password" placeholder="请输入密码"></el-input>
						</el-form-item> -->
						<!-- 8.确认密码 -->
						<!-- <el-form-item label="确认密码" prop="repassword">
						    <el-input type="password" v-model="regForm.repassword" placeholder="请再次输入密码"></el-input>
						</el-form-item> -->
						<!-- 4.公司名称 -->
						<el-form-item label="单位名称" prop="company" >
						    <el-input v-model="regForm.company" placeholder="请输入工作单位"></el-input>
						</el-form-item>
						<!-- 8.医师地址 -->
						<el-form-item label="城市" prop="stateName">
						    <el-input  v-model="regForm.stateName" placeholder="请输入城市"></el-input>
						</el-form-item>
						<!-- 5.证书编号 -->
						<el-form-item label="医师执业证书省份" prop="cardNo">
						   <!-- <el-input v-model="regForm.cardNo" placeholder="请输入医师证书省份"></el-input> -->
							<el-cascader style="width: 100%;" v-model="regForm.cardNo1" :options="sheng"
								:props="{ expandTrigger: 'hover' }" ></el-cascader>
						</el-form-item>
						<!-- 6.证书所在地 -->
						<el-form-item  label="医师执业证书执业地点" prop="credentialsAddr">
						    <el-input style="width: 80%;"  v-model="regForm.credentialsAddr" placeholder="至少输入机构名称中连续4个字即可模糊查询"></el-input>
							&nbsp;<a href="javascript:void(0)" @click="open" style="text-decoration: underline;">查看示例</a>
						</el-form-item>
						<el-form-item class="req" style="position: relative;top: -10px;">
							<span style="float: left;"><span style="color: red;">*&nbsp;</span>必填项</span>
						</el-form-item>
						<!-- 勾选协议 -->
						<el-form-item style="position: relative;top: -40px !important; ">
							<el-checkbox style="float: left;" v-model="checked" @change="xieyi">
								<span style="font-size: 14px !important;">阅读并同意山东泰克莱恩</span><a href="./tiaokuan.html" target="_blank"
									style="color: #188B9D;font-size: 14px !important;">&lt;&lt;使用条款&gt;&gt;</a>及<a href="./zhengce.html" target="_blank" 
									style="color: #188B9D;font-size: 14px !important;">&lt;&lt;隐私政策&gt;&gt;</a>
							</el-checkbox>
						</el-form-item>
						
						<!--注册按钮 -->
						<el-form-item style="position: relative;top: -60px !important; ">
							<el-button class="registerbtn" :disabled="btnIsDisabled" style="width: 100%;" type="primary" @click="submitForm('regForm')">注册</el-button>
						</el-form-item>
						
					</el-form>
				</div>
			</el-main>
			<el-footer class="f">
				Copyright © www.techlion,.com.cn All Rights Reserved.<br />
				山东泰克莱恩 0531-88762550
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	import MyHeader from '../components/MyHeader1.vue'
	import shili from '../assets/hximg/shili.jpg';
	export default{
		data:function(){
			var validatePass2 = (rule, value, callback) => {
				
			         if (value === '') {
			           callback(new Error('请再次输入密码'));
			         } else if (value !== this.regForm.password) {
			           callback(new Error('两次输入密码不一致!'));
			         } else {
			           callback();
			         }
			       }; 
			var checkPhone = (rule, value, callback) => {
				    var _this=this;
			        if (!value) {
			          return callback(new Error('手机号不能为空'));
			        }
			        setTimeout(() => {
						 
						  this.$axios.get("/checkPhone",{
						  	params:{
						  		phone:value
						  	}
						  }).then(res=>{
							 
						  	if (res.data.code==200&&res.data.data.exist=="yes") {
							
								
								_this.phoneIsExists=true;
								
						  	  callback(new Error('手机号已经被注册'));
						  	} else {
								_this.phoneIsExists=false;
						  	  callback();
						  	}
						  }).catch(err=>{
						  	callback(new Error('系统出现异常,请联系管理员'));
						  });
			            
			          
			        }, 500);
			      };
				//验证验证码
				var checkValidateCode = (rule, value, callback) => {
					if (value != this.yzCode) {
						callback(new Error('验证码输入有误'));
					} else {
						callback();
					}
				};
			return{
				zaicifasong:false,
				phoneIsExists:false,
				yzCode:'',
				phoneCode: "获取验证码",
				sheng: [],
				btnIsDisabled:true,
				checked:'',
				regForm:{
					repassword:'',//确认密码
					dname:'',//姓名
					webName:'',//昵称
					phone:'',//手机号
					company:'',//所属机构 公司名称
					cardNo1:[],//证书编号
					cardNo:'',
					credentialsAddr:'',//证书所在地
					password:'',//密码,
					stateName:'',//医师地址
					userCode:'',
				},
				
				disabled:true,//注册按钮默认是禁用状态
				qu: [],
				quValue: [],
				shi: [],
				shiValue: [],
				sheng: [],
				value: [],
				rules: {
					userCode: [
					  { required: true, message: '请输入验证码', trigger: 'blur' },
					  {
					  	min: 6,
					  	max: 6,
					  	message: "验证码为六位数字"
					  },
					  {
					  	validator: checkValidateCode,
					  	trigger: 'blur'
					  } 
					],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '长度为11个字符',
							trigger: 'blur'
						},
						/* {
							pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
							message: '手机号格式有误'
						}, */
						 { validator: checkPhone, trigger: 'blur' }
					],
					/* password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 18,
							message: '长度大于等于6个字符',
							trigger: 'blur'
						}
						
					],
					repassword: [{
							required: true,
							message: '请输入确认密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 18,
							message: '长度大于等于6个字符',
							trigger: 'blur'
						},
						 { validator: validatePass2, trigger: 'blur' }
					], */
					
					
					dname: [{
							required: true,
							message: '请输入真实的医师姓名',
							trigger: 'blur'
						}
					],
					/* webName: [{
							required: true,
							message: '请输入昵称',
							trigger: 'blur'
						}
					], */
					/* company: [{
							required: true,
							message: '请输入公司名称',
							trigger: 'blur'
						}
					], */
					cardNo: [{
							required: true,
							message: '请输入医师职业证书省份',
							trigger: 'blur'
						}
					],
					credentialsAddr: [{
							required: true,
							message: '请输入证书所在地',
							trigger: 'blur'
						}
					],
					stateName: [{
						required: true,
						message: '请输入医师地址',
						trigger: 'blur'
					}],
				},
			}
		},
		methods:{
			open() {
			        this.$alert('<img src='+shili+' style="width:100%;" />', '示例', {
			          dangerouslyUseHTMLString: true,
					  customClass:'aa'
			        });
			      },
			//获取验证码
			getCode() {
				if (this.regForm.phone == '') {
					this.$message({
						message: "请输入手机号",
						type: "warning",
						duration: 900
					});
				} else if(this.phoneIsExists==true){
					return;
				}else{
					this.zaicifasong=true;
					let num = 60;
					//this.phoneCode=num+"秒";
					this.si = setInterval(() => {
						let n = num--;
						if (n < 1) {
							//document.getElementById("c").removeAttribute("disabled");
							clearInterval(this.si);
							this.phoneCode = "获取验证码";
							this.yzCode = '';
							this.zaicifasong=false;
						} else {
							this.phoneCode = n + "秒";
						}
					}, 1000)
					this.$axios.get("/getRegCode", {
						params: {
							phone: this.regForm.phone
						}
					}).then(res => {
						this.yzCode = res.data.data.resCode;
					}).catch(err => {
						this.$message.error("请联系管理员");
					});
				}
				
			},
			//提交表单
			submitForm(formName){
				console.log(this.regForm.cardNo1[0]);
				this.regForm.cardNo=this.regForm.cardNo1[0];
				this.$refs[formName].validate((valid) => {
					if (valid) { //验证通过
					if(this.regForm.password==''){
						this.regForm.password="111111";
					}
							this.$message({
					          message: '证书信息查验中,请稍候!',
					          type: 'success',
							  duration:2000
					        });
						 //需要使用axios提交数据到后台
						this.$axios.post("/doctorRegister",JSON.stringify(this.regForm),{
							headers:{
								"Content-type":"application/json"
							}
						}).then(res=>{
							
							if(res.data.code==200){
								
								this.$alert('注册成功', '提示', {
								          confirmButtonText: '确定',
								          callback: action => {
								            this.$router.push("/");
								          }
								        });

							}else if(res.data.code==401){
								this.$alert("您所填写的医师职业证书错误,请重新填写或咨询后台工作人员。联系电话(同微信)18518573630");
							}else if(res.data.code==500){
								this.$alert("系统数据错误,请联系管理员");
							}else if(res.data.code==402){
								this.$alert("您所填写的医师职业证书已注册,请重新填写或咨询后台工作人员。联系电话(同微信)18518573630");
							}else if(res.data.code==403){
								this.$alert("该手机号已被注册");
							}
						}).catch(err=>{
							this.$alert("请联系管理员", "提示");
						})
					} else { //验证未通过
						this.$alert("提交信息不完整或有误,请检查后再提交", "提示");
						return false;
					}
				});
			},
			//勾选协议时触发的事件
			xieyi() {
				if (this.checked) {
					this.btnIsDisabled = false;
				} else {
					this.btnIsDisabled = true;
				}
			},
			//是否勾选协议的复选框
			isChecked(c){
				alert(c); 
				if(c==true){//同意协议
					//注册按钮可以使用
					this.disabled=false;//不禁用
				}else{
					this.disabled=true;//禁用
				}
			},
			//去登录
			goLogin(){
				this.$router.push('/');
			},
			//查询省市区
			getShengShiQu() {
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: 100000
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].name,
							"label": a[i].name
						};
						this.sheng.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据省查询市
			handleChange(v) {
				//每次添加市之前先清空数组
				this.shi = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.shi.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据市查询区
			handleChange1(v) {
				//每次添加区之前先清空数组
				this.qu = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.qu.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//选中区之后给ruleForm中的regionId赋值
			handleChange2(v) {
				this.regForm.regionId = v[0];
			},
		},
		components:{
			MyHeader
		},
		mounted() {
			//获取省市区
			this.getShengShiQu();
		}
	}
</script>

<style scoped="scoped">
	.register{
		width: 100%;
		height: 100%;
	}
	.el-container{
		height: 100%;
		width: 100%;
	}
	.el-main{
		height: 95%;
		width: 100%;
		
	}
	.f{
	position: relative;
	background-color: #444;
	padding: 10px 0 10px 0;
	color: #fff;
	bottom: 0rem;
	}
	.regtitle{
		padding-left: 90px;
		margin: 0;
		margin-bottom: 20px;
	}
	
	.rigster-content{
		width: 80%;
		height: 610px;
	/* 	background-image: linear-gradient(to bottom,white,lightgray); */
		background-color:#F5F5F5 ;
		border-radius: 0.3125rem;
		margin: 0px auto;
		text-align: center;
		position: relative;
		padding-top: 1.25rem;
	}
	.checkProtocal{
		text-align: left;
	}
	/* 注册按钮 */
	.registerbtn{
		/* background-color: #2B9BB3;
		border-color: #2B9BB3; */
		/* background-color: rgb(0,47,167); */
		background-color: #188B9D;
		border-radius: 0.625rem;
		font-size: 19px !important;
	}
	.el-form-item{
		/* margin-bottom:1.125rem !important; */
	}
	#c {
		width: 6.25rem;
		height: 39.15px;
		border: none;
		background: #0D8EA4;
		position: relative;
		right: -0.3125rem;
		color: white !important;
	}
	
	
</style>
