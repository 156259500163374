<template>
	<div>
		<el-header>
			<div class="header1">
				<img src="../assets/hximg/hxphone.png" width="30px" style="vertical-align: middle;" >&nbsp;<span style="margin-right: 1.25rem;font-weight: normal;">0531-88762550</span>
				<!-- <img src="../assets/hximg/hxhome.png" width="22.5px" style="vertical-align: middle;padding-bottom: 0.135rem;" >&nbsp;<a style="margin-right: 1.25rem;color:#000000;" href="http://www.techlion.com.cn/">官网首页</a> -->
				<img src="../assets/mail.png" width="30" height="30" style="vertical-align: middle;" />&nbsp;<span class="phone" style="padding-left: 0.625rem;">chiyangyang1@foxmail.com</span>
			</div>
<!-- 			<div class="header2">
				<img src="../assets/mail.png" width="30" height="30" style="vertical-align: middle;" />
				<span class="phone" style="padding-left: 0.625rem;">portal@forestadent.com</span>
			</div> -->
			<!-- <div class="header3">
				<span>欢迎您:<span class="loginName">{{userName}}</span></span>
			</div> -->
			<!-- <div class="header4">
				<el-dropdown>
				  <img alt="头像" src="../assets/head.png" width="35" height="35" />
				  <el-dropdown-menu slot="dropdown">
				    <el-dropdown-item><a href="javascript:void(0)" @click="editPersonInfo">个人中心</a></el-dropdown-item>
				    <el-dropdown-item><a href="javascript:void(0)" @click="logout">退出</a></el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
			</div> -->
		</el-header>
		<div class="bo"></div>
		<!-- 个人中心dialog -->
		<el-dialog title="个人中心" :visible.sync="dialogFormVisible">
		  <el-form :model="form">
			<el-input v-model="form.id" autocomplete="off" style="display: none;"></el-input>
		    <el-form-item label="公司名称">
		      <el-input v-model="form.company" autocomplete="off" placeholder="请输入公司名称"></el-input>
		    </el-form-item>
			<el-form-item label="医师姓名">
			  <el-input v-model="form.dname" autocomplete="off" placeholder="请输入医师姓名"></el-input>
			</el-form-item>
			<el-form-item label="联系方式">
			  <el-input v-model="form.phone" autocomplete="off" placeholder="请输入联系方式"></el-input>
			</el-form-item>
		    <el-form-item label="所在地区" >
		     <el-cascader
		       v-model="shengValue"
		       :options="sheng"
		       :props="{ expandTrigger: 'hover' }"
		       @change="shengHandleChange"
			   placeholder="请选择省"
		      ></el-cascader>
			 <el-cascader
			   v-model="shiValue"
			   :options="shi"
			   :props="{ expandTrigger: 'hover' }"
			    placeholder="请选择市"
			   @change="shiHandleChange"></el-cascader>
			  <el-cascader
			    v-model="quValue"
			    :options="qu"
			    :props="{ expandTrigger: 'hover' }"
				 placeholder="请选择区"
			    @change="quHandleChange"></el-cascader>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				hidden:true,
				loginUserName: '张三',
				dialogFormVisible:false,
				form: {
				          company: '',
				          dname: '',
				          phone: '',
				          date2: '',
				          stateName: '',
				          id:''
				        },
				formLabelWidth: '120px',
				sheng:[],
				shengValue:[],
				shi:[],
				shiValue:[],
				qu:[],
				quValue:[]
			}
		},
		props:{
			userName:{
				type:String,
				default:'李四',
			}
		},
		methods:{
			//显示个人中心
			showPersonInfo(){
				console.log("showPersonInfo");
				this.hidden=false;
			},
			//隐藏个人中心
			hidePersonInfo(){
				this.hidden=true;
			},
			//退出
			logout(){
				this.$confirm('确定要退出吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
				         sessionStorage.removeItem("loginInfo");
				         sessionStorage.removeItem("oid");
				         this.$router.push("/");
				        }).catch(() => {
				          this.$alert("您取消了退出操作","提示");         
				        });
				
			},
			//单击个人中心弹出dialog
			editPersonInfo(){
				this.dialogFormVisible=true;
			},
			//根据医生doctorId查询医生信息
			getDoctoryId(){
				
			},
			//查询省
			getSheng(){
				this.$axios.get("queryNextLevelRegions",{
								   params:{
									   pid:100000
								   }
				}).then(res=>{
								   let a=res.data.data
								   for(let i=0;i<a.length;i++){
									   let obj={"value":a[i].id,"label":a[i].name};
									   this.sheng.push(obj);
								   }
				}).catch(err=>{
								   this.$message.error("请联系管理员");
				});
			},
			//省发生变化的时候查询市
			shengHandleChange(v){
				console.log();
				//每次添加市之前先清空数组
				this.shi=[];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions",{
								   params:{
									   pid:v[0]
								   }
				}).then(res=>{
								   let a=res.data.data
								   for(let i=0;i<a.length;i++){
									   let obj={"value":a[i].id,"label":a[i].name};
									   this.shi.push(obj);
								   }
				}).catch(err=>{
								   this.$message.error("请联系管理员");
				});
			},
			//市发生变化的时候查询区
			shiHandleChange(v){
				//每次添加区之前先清空数组
				this.qu=[];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions",{
								   params:{
									   pid:v[0]
								   }
				}).then(res=>{
								   let a=res.data.data
								   for(let i=0;i<a.length;i++){
									   let obj={"value":a[i].id,"label":a[i].name};
									   this.qu.push(obj);
								   }
				}).catch(err=>{
								   this.$message.error("请联系管理员");
				});
			},
			//区发生变化
			quHandleChange(){
				
			}
		},
		mounted:function(){
			//查询省
			this.getSheng();
			this.shengValue=[120000]
			this.shengHandleChange([120000])
			this.shiValue=[120100]
			this.shiHandleChange([120100]);
			this.quValue=[120102]
		}
	}
</script>

<style scoped="scoped">
	.el-header {
		height: 5rem !important;
		line-height: 4.75rem;
		 background-image: url(../assets/tklnlogo1.png);
		background-repeat: no-repeat;
		background-position: 1.25rem 0.425rem;
		/* background-size: 20% 100%; */
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0.625rem;
	}
	.header1,
	.header2,
	.header3,
	.header4 {
		display: inline-block;
		margin-left: 1.25rem;
	}
	
	.header4 {
		height: 2.1875rem;
	}
	.loginName {
		color: #2B9BB3;
		font-weight: bold;
	}
	
	/* dddddd */
	 .el-dropdown {
	    vertical-align: top;
	  }
	  .el-dropdown + .el-dropdown {
	    margin-left: 15px;
		
	  }
	  .el-icon-arrow-down {
	    font-size: 12px;
	  }
		  
	  .el-dropdown-menu{
		  margin-top: -0.925rem;
	  }
	  
	  /* */
	.el-select{
		float: left !important;
		width: 25%;
	}
	.el-cascader{
		width: 25% !important;
		}
	 .sels{
		 position: relative;
		 left: -15.5rem;
	 }
	 .sels a{
		 font-size: 1.5625rem;
		 font-weight: bolder;
	 }
</style>
