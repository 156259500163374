import { render, staticRenderFns } from "./MyHeader1.vue?vue&type=template&id=fb9df3c4&scoped=true&"
import script from "./MyHeader1.vue?vue&type=script&lang=js&"
export * from "./MyHeader1.vue?vue&type=script&lang=js&"
import style0 from "./MyHeader1.vue?vue&type=style&index=0&id=fb9df3c4&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb9df3c4",
  null
  
)

export default component.exports